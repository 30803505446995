// @flow

import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { Container } from '../../components/basic/Grid'
import ReferenceItem from './Components/ReferenceItem'
import baseStyles from './References.scss'

/* eslint-disable max-len */
const iberostar = <StaticImage src='../../images/references/previews/teaser-iberostar.png' alt='' objectFit='cover' className='image' />
const hlx = <StaticImage src='../../images/references/previews/teaser-hlx.png' alt='' objectFit='cover' className='image' />
const emea = <StaticImage src='../../images/references/previews/teaser-mitsubishi.jpg' alt='' objectFit='cover' className='image' />
const universal = <StaticImage src='../../images/references/previews/teaser-universal.png' alt='' objectFit='cover' className='image' />
const sreisewelt = <StaticImage src='../../images/references/previews/teaser-s-reisewelt.png' alt='' objectFit='cover' className='image' />
const stewa2x = <StaticImage src='../../images/references/previews/teaser-Stewa2x.jpg' alt='' objectFit='cover' className='image' />
const jazhotels2x = <StaticImage src='../../images/references/previews/teaser-jazHotels2x.jpg' alt='' objectFit='cover' className='image' />
const byebye2x = <StaticImage src='../../images/references/previews/teaser-byebye2x.jpg' alt='' objectFit='cover' className='image' />
const lastminutech2x = <StaticImage src='../../images/references/previews/teaser-lastminute2x.jpg' alt='' objectFit='cover' className='image' />
const aldiana2x = <StaticImage src='../../images/references/previews/teaser-aldiana2x.jpg' alt='' objectFit='cover' className='image' />
const alltours2x = <StaticImage src='../../images/references/previews/teaser-alltours2x.jpg' alt='' objectFit='cover' className='image' />
const betty2x = <StaticImage src='../../images/references/previews/teaser-betty2x.jpg' alt='' objectFit='cover' className='image' />
const findus2x = <StaticImage src='../../images/references/previews/teaser-findus2x.jpg' alt='' objectFit='cover' className='image' />
const lufthansa2x = <StaticImage src='../../images/references/previews/teaser-lufthansa2x.jpg' alt='' objectFit='cover' className='image' />
const plattenplaner2x = <StaticImage src='../../images/references/previews/teaser-plattenplaner.png' alt='' objectFit='cover' className='image' />
const promaterial2x = <StaticImage src='../../images/references/previews/teaser-promaterial.png' alt='' objectFit='cover' className='image' />
const stern2x = <StaticImage src='../../images/references/previews/teaser-stern2x.jpg' alt='' objectFit='cover' className='image' />
const swissholidays2x = <StaticImage src='../../images/references/previews/teaser-swissholidays2x.jpg' alt='' objectFit='cover' className='image' />
const unitedCharity2x = <StaticImage src='../../images/references/previews/teaser-unitedCharity2x.jpg' alt='' objectFit='cover' className='image' />
const weekend2x = <StaticImage src='../../images/references/previews/teaser-weekend2x.jpg' alt='' objectFit='cover' className='image' />
const wickeder2x = <StaticImage src='../../images/references/previews/teaser-wickeder2x.jpg' alt='' objectFit='cover' className='image' />
const berge = <StaticImage src='../../images/references/previews/teaser-berge-and-meer.jpg' alt='' objectFit='cover' className='image' />
/* eslint-enable max-len */

type Props = {
  ...StyleProps
}

const References = ({
  styles
}: Props) => (
  <Container className={styles.root} fluid>
    <div className={styles.previewList}>
      <ReferenceItem
        styles={styles.item}
        image={berge}
        link="https://www.berge-meer.de/"
        altText="berge-meer.de"
      />
      <ReferenceItem
        styles={styles.item}
        image={iberostar}
        link="https://iberostarurlaub.com/de-de"
        altText="iberostarurlaub.com"
      />
      <ReferenceItem
        styles={styles.item}
        image={hlx}
        link="https://www.hlx.com/de-de"
        altText="www.hlx.com"
      />
      <ReferenceItem
        styles={styles.item}
        image={emea}
        link="https://emea.mitsubishielectric.com/fa"
        altText="emea.mitsubishielectric.com"
      />
      <ReferenceItem
        styles={styles.item}
        image={universal}
        link="https://www.universaltravel.de/"
        altText="universaltravel.de"
      />
      <ReferenceItem
        styles={styles.item}
        image={sreisewelt}
        link="https://www.s-reisewelt.de/"
        altText="s-reisewelt.de"
      />
      <ReferenceItem styles={styles.item}
        image={stewa2x} link="https://stewa.de/" altText="stewa.de"
      />
      <ReferenceItem styles={styles.item}
        image={jazhotels2x} link="https://www.jazhotels.com/" altText="jazhotels.com"
      />
      <ReferenceItem delay={300} styles={styles.item}
        image={weekend2x} link="https://www.weekend.com/" altText="weekend.com"
      />
      <ReferenceItem delay={300} styles={styles.item}
        image={betty2x} altText="betty"
      />
      <ReferenceItem delay={300} styles={styles.item}
        image={aldiana2x} link="https://www.aldiana.com/" altText="aldiana"
      />
      <ReferenceItem delay={300} styles={styles.item}
        image={alltours2x} link="https://alltours.de/" altText="alltours"
      />
      <ReferenceItem delay={300} styles={styles.item}
        image={byebye2x} link="https://www.byebye.de/" altText="byebye"
      />
      <ReferenceItem delay={300} styles={styles.item}
        image={plattenplaner2x} link="https://plattenplaner.de/" altText="plattenplaner"
      />
      <ReferenceItem delay={300} styles={styles.item}
        image={lastminutech2x} link="https://ferien.lastminute.ch/search" altText="Lastminute.ch"
      />
      <ReferenceItem delay={600} styles={styles.item}
        image={lufthansa2x} link="https://www.lufthansaholidays.com/" altText="lufthansa"
      />
      <ReferenceItem delay={600} styles={styles.item}
        image={stern2x} altText="stern"
      />
      <ReferenceItem delay={600} styles={styles.item}
        image={promaterial2x} link="https://www.promaterial.com/" altText="promaterial"
      />
      <ReferenceItem delay={900} styles={styles.item}
        image={swissholidays2x} link="https://www.swissholidays.com/" altText="swissholidays"
      />
      <ReferenceItem delay={900} styles={styles.item}
        image={findus2x} link="https://findus-jugendhilfe.de/" altText="findus"
      />
      <ReferenceItem delay={900} styles={styles.item}
        image={unitedCharity2x} link="https://www.unitedcharity.de/" altText="unitedCharity"
      />
      <ReferenceItem delay={900} styles={styles.item}
        image={wickeder2x} link="https://www.wickeder.de/" altText="wickeder"
      />
    </div>
  </Container>
)

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(References)

// @flow

import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import Layout from '../components/Layout/index'
import Header from '../modules/Header'
import Footer from '../modules/Footer'
import SubpageHeroTeaser from '../components/SubpageHeroTeaser'
import Clients from '../components/Clients'
import ClientOverview from '../components/Clients/ClientOverview'
import References from '../components/References'
import Products from '../components/Products'
import SEO from '../components/seo'
import ScrollAnimation from 'react-animate-on-scroll'
import CaseTeasers from '../components/CaseStudy/CaseTeasers'

import metaDescription from '../messages/pageMetaDescriptions.lang'
import messages from '../messages/references.lang'
import pageTitle from '../messages/pageTitles.lang'
import baseStyles from './references.scss'
import { StaticImage } from 'gatsby-plugin-image'

type Props = {}

const HeroTeaserImage = (
  <StaticImage
    src="../images/heroteaser/references_heroteaser_bg_desktop.jpg"
    alt=""
    objectFit="cover"
    className="image"
  />
)

const IndexPage = ({ styles }: StyleProps) => {
  const { formatMessage, locale } = useIntl()

  return (
    <Layout footer={<Footer />} header={<Header withTeaser />}>
      <SEO
        title={formatMessage(pageTitle.TitleReferences)}
        description={formatMessage(metaDescription.referencesDescription)}
        lang={locale}
      />
      <SubpageHeroTeaser title={formatMessage(messages.pageTitleReferences)} image={HeroTeaserImage} />
      <ScrollAnimation animateIn="fadeInUp" animateOnce>
        <Clients ancor styles={styles.clients} />
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInUp" animateOnce>
        <CaseTeasers
          list={['smm', 'dertouristik', 'lhh', 'plattenplaner', 'aldiana', 'etravel', 'weekend', 'bedfinder']}
          allButton
          bottomTitle
        />
      </ScrollAnimation>
      <References />
      <ScrollAnimation animateIn="fadeInUp" animatePreScroll={false} animateOnce>
        <ClientOverview />
      </ScrollAnimation>
      <Products />
    </Layout>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(IndexPage)
